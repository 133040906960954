.signup {
  font-size: 20px;
  font-weight: 300;
  margin-left: 50px;
  margin-top: -15px;
}
@media screen and (max-width: 768px) {
  .signup {
    margin-left: 10px;
    margin-top: 0;
  }
}
@media screen and (max-width: 1024px) {
  .signup {
    margin-left: 10px;
  }
}